import React, { useEffect, useState } from "react";
import axios from "axios";
import JSONPretty from "react-json-pretty";
function App() {
  const [form, setForm] = useState({
    User: "",
    apikey:
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJ0ZXN0IiwibmFtZSI6InNpdWQiLCJpYXQiOjE1MTYyMzkwMjJ9.v3O1o5KbAglcNqQzJsjHU-FR6j8OD6RPoBwhKGZ2Slc",
    SQL: "",
  });
  const [result, setResult] = useState("");
  function handleSubmit(e) {
    e.preventDefault();
    axios.post("https://api.mdmw.ziplelab.com/apisever", form).then((res) => {
      console.log(res.data);
      setResult(JSON.stringify(res.data));
    });
  }

  const [array, setArray] = useState([]);
  async function handleGet() {
    await axios
      .get("https://api.mdmw.ziplelab.com/akey")
      .then((res) => {
        console.log(res.data);
        setArray(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    handleGet();
  }, []);

  useEffect(() => {
    handleGetUser();
  }, [form.apikey]);

  async function handleGetUser() {
    await axios
      .get("https://api.mdmw.ziplelab.com/user", {
        headers: {
          "Content-Type": "application/json",
        },
        params: {
          apikey: form.apikey,
        },
      })

      .then((res) => {
        console.log(res.data);
        setForm({ ...form, User: res.data.User });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <div className="App">
      <div
        style={{
          widht: "90%",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div style={{ width: "60%", marginTop: "20px" }}>
          <h4>HL 만도DB Middleware API TEST Area</h4>
          <form onSubmit={(e) => handleSubmit(e)}>
            <table style={{ width: "100%" }}>
              <tbody>
                <tr>
                  <td>
                    <label htmlFor="User">User</label>
                  </td>
                  <td>
                    <input
                      type="text"
                      id="User"
                      name="User"
                      style={{ width: "50%" }}
                      value={form.User}
                      onChange={(e) =>
                        setForm({ ...form, User: e.target.value })
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label htmlFor="apikey">API Key</label>
                  </td>
                  <td>
                    <select
                      id="apikey"
                      name="apikey"
                      style={{ width: "50%" }}
                      value={form.apikey}
                      onChange={(e) =>
                        setForm({ ...form, apikey: e.target.value })
                      }
                    >
                      {array.map((item, index) => {
                        return (
                          <option key={index} value={item}>
                            {item}
                          </option>
                        );
                      })}
                    </select>
                  </td>
                </tr>
                <tr>
                  <td>
                    <label htmlFor="Database">Database</label>
                  </td>
                  <td>test_DB</td>
                </tr>
                <tr>
                  <td>
                    <label htmlFor="SQL">SQL</label>
                  </td>
                  <td>
                    <textarea
                      id="SQL"
                      name="SQL"
                      style={{ width: "50%" }}
                      onChange={(e) =>
                        setForm({ ...form, SQL: e.target.value })
                      }
                    ></textarea>
                  </td>
                </tr>
                <tr>
                  <td>
                    <button type="submit">API 전송</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
        </div>

        <div
          style={{
            width: "60%",
            marginTop: "20px",
            borderTop: "1px solid black",
          }}
        >
          <h4 htmlFor="Result">Result</h4>
          <JSONPretty
            data={result || {}}
            onJSONPrettyError={(e) => console.error(e)}
          ></JSONPretty>
        </div>
      </div>
    </div>
  );
}

export default App;
